<template>
  <div id="chartoptions">
    <el-form @submit.native.prevent ref="form">
      <el-collapse v-model="activeNames">
        <el-collapse-item
          v-if="
            chartType !== 'treemap' &&
              chartType !== 'heatmap' &&
              isCartesianChart &&
              seriesLen > 1
          "
          title="Common Options"
          name="common"
        >
          <el-form-item label="Stacked Chart">
            <el-switch
              inactive-color="#24272b"
              @change="updateChart"
              v-model="chart.stacked"
            ></el-switch>
          </el-form-item>

          <el-form-item class="mt2" label="100% Stacked">
            <el-radio-group
              @change="updateChart"
              size="small"
              v-model="chart.stackType"
            >
              <el-radio-button label="normal">Normal</el-radio-button>
              <el-radio-button label="100%">100%</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-collapse-item>

        <el-collapse-item v-if="chartType === 'line'" title="Line" name="line">
          <OptionsLine></OptionsLine>
        </el-collapse-item>

        <el-collapse-item v-if="chartType === 'area'" title="Area" name="area">
          <OptionsLine></OptionsLine>
        </el-collapse-item>

        <el-collapse-item
          v-if="chartType === 'bar' || chartType === 'rangeBar'"
          title="Bar"
          name="bar"
        >
          <OptionsBar></OptionsBar>
        </el-collapse-item>

        <el-collapse-item v-if="chartType === 'pie'" title="Pie" name="pie">
          <OptionsPie :grid="grid"></OptionsPie>
        </el-collapse-item>

        <el-collapse-item
          v-if="chartType === 'donut'"
          title="Donut"
          name="donut"
        >
          <OptionsDonut :grid="grid"></OptionsDonut>
        </el-collapse-item>

        <el-collapse-item
          v-if="chartType === 'radar'"
          title="Radar"
          name="radar"
        >
          <OptionsRadar></OptionsRadar>
        </el-collapse-item>

        <el-collapse-item
          v-if="chartType === 'radialBar'"
          title="RadialBar"
          name="radialBar"
        >
          <OptionsRadialBar :grid="grid"></OptionsRadialBar>
        </el-collapse-item>

        <el-collapse-item
          v-if="chartType === 'heatmap'"
          title="Heatmap"
          name="heatmap"
        >
          <OptionsHeatmap></OptionsHeatmap>
        </el-collapse-item>

        <el-collapse-item
          v-if="chartType === 'treemap'"
          title="Treemap"
          name="treemap"
        >
          <OptionsTreemap></OptionsTreemap>
        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {
    OptionsBar: () => import("./chartoptions/bar.component"),
    OptionsLine: () => import("./chartoptions/line.component"),
    OptionsPie: () => import("./chartoptions/pie.component"),
    OptionsDonut: () => import("./chartoptions/donut.component"),
    OptionsRadialBar: () => import("./chartoptions/radialbar.component"),
    OptionsRadar: () => import("./chartoptions/radar.component"),
    OptionsHeatmap: () => import("./chartoptions/heatmap.component"),
    OptionsTreemap: () => import("./chartoptions/treemap.component"),
  },
  data() {
    return {
      activeNames: [
        "common",
        "line",
        "area",
        "bar",
        "pie",
        "donut",
        "radialBar",
        "radar",
        "heatmap",
        "rangeBar",
        "treemap",
      ],
      seriesLen: 1,
      chart: {
        stacked: false,
        stackType: "normal",
      },
      grid: {
        padding: {
          top: 0,
          left: 10,
          right: 0,
          bottom: 12,
        },
      },
      plotOptions: {
        heatmap: {
          radius: 2,
          shadeIntensity: 0.5,
        },
      },
    };
  },
  watch: {
    chartConfig: function(newProp) {
      this.setNewOptions(newProp);
    },
  },
  mounted: function() {
    const opts = this.getChartConfig().w.config;
    this.setNewOptions(opts);
  },
  methods: {
    setNewOptions(opts) {
      this.seriesLen = opts.series.length;

      this.chart = {
        stacked: opts.chart.stacked,
        stackType: opts.chart.stackType,
      };
    },
    updateChart: function() {
      let yaxis = [];
      if (this.chart.stackType === "normal") {
        yaxis = [
          {
            min: undefined,
            max: undefined,
          },
        ];
      } else {
        yaxis = [
          {
            min: 0,
            max: 100,
          },
        ];
      }

      this.updateChartOptions({
        chart: {
          stacked: this.chart.stacked,
          stackType: this.chart.stackType,
        },
        yaxis,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
